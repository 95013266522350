import marqueeImg1 from '../assets/images/Group 88.png'
import marqueeImg2 from '../assets/images/Group 89.png'
import marqueeImg3 from '../assets/images/Group 90.png'
import marqueeImg4 from '../assets/images/Group 91.png'
import marqueeImg5 from '../assets/images/Group 92.png'
import slideAbout1 from '../assets/images/about/about1.png'
import slideAbout2 from '../assets/images/about/about2.png'
import slideAbout3 from '../assets/images/about/about3.png'
import slideAbout4 from '../assets/images/about/about4.png'
import slideAbout5 from '../assets/images/about/about5.png'
import slideAbout6 from '../assets/images/about/about6.png'
import slideAbout7 from '../assets/images/about/about7.png'
import slideAbout8 from '../assets/images/about/about8.png'
import slideAbout9 from '../assets/images/about/about9.png'
import slideAbout10 from '../assets/images/about/about10.png'
import furnitureChair from '../assets/images/chair.png'
import slideDesigners1 from '../assets/images/designers/des1.png'
import slideDesigners2 from '../assets/images/designers/des2.png'
import slideDesigners3 from '../assets/images/designers/des3.png'
import slideDesigners4 from '../assets/images/designers/des4.png'
import slideDesigners5 from '../assets/images/designers/des5.png'
import slideDesigners6 from '../assets/images/designers/des6.png'
import slideDesigners7 from '../assets/images/designers/des7.png'
import slideDesigners8 from '../assets/images/designers/des8.png'
import furnitureImg1 from '../assets/images/furniture1.png'
import furnitureImg2 from '../assets/images/furniture2.png'
import furnitureImg3 from '../assets/images/furniture3.png'
import furnitureImg4 from '../assets/images/furniture4.png'
import furnitureImg5 from '../assets/images/furniture5.png'
import furnitureImg6 from '../assets/images/furniture6.png'
import furnitureImg7 from '../assets/images/furniture7.png'
import furnitureImg8 from '../assets/images/furniture8.png'
import furnitureImg9 from '../assets/images/furniture9.png'
import furnitureImg10 from '../assets/images/furniture10.png'
import recommendationImg1 from '../assets/images/recommendations/general.png'
import recommendationJakkardImg from '../assets/images/recommendations/jakkard.png'
import RecommendationImg1 from '../assets/images/recommendations/recommendations1.png'
import RecommendationImg2 from '../assets/images/recommendations/recommendations2.png'
import recommendationRogojImg from '../assets/images/recommendations/rogoj.png'
import recommendationShanillImg from '../assets/images/recommendations/shanill.png'
import recommendationVelurImg from '../assets/images/recommendations/velur.png'
import recommendationVelvetImg from '../assets/images/recommendations/velvet.png'
import recommendationWoolImg from '../assets/images/recommendations/wool.png'
import slide2 from '../assets/images/slide2.png'
import slide2Mob from '../assets/images/slide2mob.png'
import slide3 from '../assets/images/slide3.png'
import slide4 from '../assets/images/slide4.png'
import slide4Mob from '../assets/images/slide4mob.png'
import slide5 from '../assets/images/slide5.png'
import slide5Mob from '../assets/images/slide5mob.png'
import slide6 from '../assets/images/slide6.png'
import slide1 from '../assets/images/slide.jpg'

export const FurnitureShowCaseData = [
  {
    id: 13,
    title: 'Стулья',
    buttonText: 'перейти в каталог',
    img: furnitureImg1,
  },
  {
    id: 100,
    title: 'СКИДКИ',
    buttonText: 'выгодные предложения',
    img: furnitureImg2,
    nav: true,
  },
  {
    id: 14,
    title: 'СТОЛЫ',
    buttonText: 'перейти в каталог',
    img: furnitureImg10,
  },
]

export const FurnitureWithMenuData = [
  {
    id: 15,
    title: 'ПУФЫ',
    img: furnitureChair,
  },
  {
    id: 16,
    title: 'ДИВАНЫ',
    img: furnitureImg4,
  },
  {
    id: 23,
    title: 'КОМПЛЕКТЫ:',
    img: furnitureImg5,
  },
]

export const FurnitureCollectionData = [
  {
    id: 17,
    title: 'ПРИКРОВАТНЫЕ\n' + 'ТУМБЫ',
    img: furnitureImg5,
  },
  {
    id: 18,
    title: 'ОФИСНАЯ\n' + 'МЕБЕЛЬ',
    img: furnitureImg6,
  },
  {
    id: 19,
    title: 'Журнальные\n' + 'столики',
    img: furnitureImg3,
  },
  {
    id: 20,
    title: 'Кресла',
    img: furnitureImg7,
  },
  {
    id: 21,
    title: 'Декор',
    img: furnitureImg8,
  },
  {
    id: 22,
    title: 'Уличная\n' + 'мебель',
    img: furnitureImg9,
  },
]

export const RecommendationsData = [
  {
    id: 80,
    title: 'ОБЩИЕ\n' + 'РЕКОМЕНДАЦИИ',
    img: recommendationImg1,
  },
  {
    id: 81,
    title: 'ВЕЛЮР',
    img: recommendationVelurImg,
  },
  {
    id: 82,
    title: 'ИСКУССТВЕННАЯ\n' + 'ШЕРСТЬ',
    img: recommendationWoolImg,
  },
  {
    id: 83,
    title: 'РОГОЖКА',
    img: recommendationRogojImg,
  },
  {
    id: 84,
    title: 'ШЕННИЛ',
    img: recommendationShanillImg,
  },
  {
    id: 85,
    title: 'ЖАККАРД',
    img: recommendationJakkardImg,
  },
  {
    id: 86,
    title: 'ВЕЛЬВЕТ',
    img: recommendationVelvetImg,
  },
]

export let mockImages = []
if (window.innerWidth > 1440) {
  mockImages = [
    marqueeImg1,
    marqueeImg2,
    marqueeImg3,
    marqueeImg4,
    marqueeImg5,
    marqueeImg1,
    marqueeImg2,
    marqueeImg3,
    marqueeImg4,
    marqueeImg5,
  ]
} else {
  mockImages = [marqueeImg1, marqueeImg2, marqueeImg3, marqueeImg4, marqueeImg5]
}
// export const mockImages = [
//   marqueeImg1,
//   marqueeImg2,
//   marqueeImg3,
//   marqueeImg4,
//   marqueeImg5,
//   marqueeImg1,
//   marqueeImg2,
//   marqueeImg3,
//   marqueeImg4,
//   marqueeImg5,
// ]

const isMobile = window.innerWidth <= 768
export const mockImagesSlider = [
  {
    img: slide1,
    title: 'MEDINA KAS',
    text: 'Магазин дизайнерской мебели',
  },
  {
    img: isMobile ? slide2Mob : slide2,
    title: 'ТРЕНДОВЫЙ ТОВАР',
  },
  // {
  //   img: slide3,
  //   title: 'ВЫГОДНОЕ\n' + 'СПЕЦ ПРЕДЛОЖЕНИЕ',
  // },
  {
    img: isMobile ? slide4Mob : slide4,
    title: 'НОВИНКА',
  },
  {
    img: isMobile ? slide5Mob : slide5,
    title: 'СОТРУДНИЧЕСТВО',
  },
  // {
  //   img: slide6,
  //   title: 'ВЫГОДНОЕ\n' + 'СПЕЦ ПРЕДЛОЖЕНИЕ',
  // },
]

export const mockMenuPurposes = [
  {
    id: 9,
    name: 'гостиные',
    filterIds: [9, 11],
  },
  {
    id: 10,
    name: 'столовые',
    filterIds: [10, 13],
  },
  {
    id: 12,
    name: 'спальни',
    filterIds: [12],
  },
  {
    id: 15,
    name: 'офис',
    filterIds: [15],
  },
  {
    id: 14,
    name: 'под открытым небом',
    filterIds: [14],
  },
]

export const mockImagesSliderDesigners = [
  {
    img: slideDesigners1,
    header: 'ВЫГОДНОЕ СОТРУДНИЧЕСТВО С ПРОФЕССИОНАЛАМИ',
    text: 'Магазин премиальной мебели Medina Kas приглашает к сотрудничеству дизайнеров интерьера. Мы помогаем как закупить, так и произвести уникальные по вашим запросам изделия. Мы уверены, что наше сотрудничество будет по-настоящему взаимовыгодным.',
  },
  {
    img: slideDesigners2,
    header: 'ПОЧЕМУ ВЫБИРАЮТ НАС?',
    number: '1',
    title: 'ШИРОКИЙ АССОРТИМЕНТ МЕБЕЛИ',
    text: 'Мы предлагаем широкий выбор дизайнерской мебели, доступной для каждого проекта. Наш ассортимент отвечает последним тенденциям рынка и удовлетворяет потребности даже самых взыскательных клиентов.',
  },
  {
    img: slideDesigners3,
    number: '2',
    title: 'НАЛИЧИЕ И ИНДИВИДУАЛЬНЫЙ ЗАКАЗ',
    text: 'Большая часть ассортимента доступна на складе, что позволяет сократить сроки поставки. Если вам нужен уникальный дизайн, мы создадим мебель по вашим пожеланиям и требованиям.',
  },
  {
    img: slideDesigners4,
    number: '3',
    title: 'ВЫСОКОЕ КАЧЕСТВО',
    text: 'Мы сотрудничаем только с проверенными производителями, что гарантирует высокое качество материалов и исполнения. Каждое изделие проходит строгий контроль качества.',
  },
  {
    img: slideDesigners5,
    number: '4',
    title: 'ГИБКИЕ УСЛОВИЯ СОТРУДНИЧЕСТВА',
    text: 'Для дизайнеров интерьера мы предлагаем особые условия — персональные скидки, эксклюзивные предложения и гибкие системы оплаты. Мы готовы предложить индивидуальные условия под ваши проекты.',
  },
  {
    img: slideDesigners6,
    number: '5',
    title: 'ПОДДЕРЖКА НА ВСЕХ ЭТАПАХ',
    text: 'Наша команда профессионалов всегда готова помочь вам на каждом этапе работы, от консультаций по материалам до доставки и сборки. Мы стремимся к максимальной эффективности и удобству для наших партнеров.',
  },
  {
    img: slideDesigners7,
    number: '6',
    title: 'ОПЕРАТИВНАЯ ДОСТАВКА',
    text: 'Мы организуем быструю доставку по всей стране, обеспечивая оперативное получение мебели для вашего проекта. Для нас важно, чтобы вы и ваши клиенты оставались довольны не только качеством продукции, но и уровнем сервиса.',
  },
  {
    img: slideDesigners8,
    header: 'НЕ БУДЕМ СКРЫВАТЬ: РАБОТА С ДИЗАЙНЕРАМИ ДЛЯ НАС В ПРИОРИТЕТЕ!',
    text: 'Мы хотим создать профессиональное сообщество, разделяющее наши ценности и подходы. Наш менеджер свяжется с вами, чтобы обсудить детали сотрудничества.',
  },
]

export const mockImagesSliderAbout = [
  {
    img: slideAbout1,
    header: 'ПРЕДСТАВЬТЕ МИР',
    text: 'полный современных и вдохновляющих объектов, пространств и впечатлений. Дизайн всегда формировал нашу культуру, создавая системы, города и товары. Но сегодня дизайн — это не просто решение проблем, а украшение нашей жизни. Он улучшает её поэтически, эстетически, экспериментально и эмоционально.',
  },
  {
    img: slideAbout2,
    header: 'МЕНЯ ЗОВУТ МЕДИНА,',
    title: 'и моя мечта — видеть, как люди живут в ритме нашего времени,',
    text: 'участвуют в современном мире и освобождаются от ностальгии и устаревших традиций. Мы должны быть осознанными и настроенными на этот момент. Если изменить мир — значит изменить человеческую природу, то давайте начнем прямо сейчас!"',
  },
  {
    img: slideAbout3,
    header: 'Я САМА БЫЛА ДИЗАЙНЕРОМ,',
    text: 'и знаю какие боли есть у коллег, поэтому мы предлагаем комплексно решить все трудности связанные с мебелью',
  },
  {
    img: slideAbout4,
    header: 'Кроме того,',
    text: 'мы активно развиваемся в двух ключевых направлениях, дополняющих наш основной ассортимент:',
  },
  {
    img: slideAbout5,
    header: 'ПРЕМИАЛЬНЫЕ КУХНИ И КОРПУСНАЯ МЕБЕЛЬ',
    text: 'Мы предлагаем современные и функциональные кухонные решения, которые гармонично впишутся в любой интерьер. Качественные материалы и продуманный дизайн делают наши кухни удобными и долговечными.',
  },
  {
    img: slideAbout6,
    header: 'ПРЕМИАЛЬНЫЕ СТОЛЫ',
    text: 'Наши мойки сочетают стиль и практичность, они изготовлены из надежных материалов и отлично подходят для повседневного использования.',
  },
  {
    img: slideAbout7,
    header: 'ЭТИ НАПРАВЛЕНИЯ ДОПОЛНЯЮТ НАШ ОСНОВНОЙ АССОРТИМЕНТ',
    text: 'и позволяют предлагать более комплексные решения интерьерного вопроса наших клиентов.',
  },
  {
    img: slideAbout8,
    header: 'НАШИ ЦЕННОСТИ',
    text:
      'В Medina Kas мы строим культуру изнутри, ориентируясь на три ключевых принципа:\n' +
      'профессиональный рост и развитие;\n' +
      'внимание к потребностям клиентов с учетом современных трендов;\n' +
      'конкурентные цены.',
  },
  {
    img: slideAbout9,
    header: 'Наш приоритет',
    text: ' — удовлетворение эстетических запросов клиентов, что возможно благодаря признанию и поддержке труда наших сотрудников. В сплоченной команде мы уверены в качестве своей работы и готовы справиться с любыми задачами. Мы называем это The Medina Kas Way.    ',
  },
  {
    img: slideAbout10,
    header: 'Наши принципы:',
    text:
      'Развитие: постоянно улучшайся и будь открыт новому.\n' +
      'Достижение целей: ставь цели и находи пути их успешного выполнения.\n' +
      'Новые решения: создавай реальность, независимо от обстоятельств.\n' +
      'Слушать и слышать: воспринимай идеи других и совершен ствуй свои.',
  },
]

export const mockDataRecommendationCard = {
  image: RecommendationImg1,
  title: 'СУХАЯ ЧИСТКА',
  content: [
    {
      id: 1,
      text: 'Влажная чистка не показана для проведения для повседневного ухода за мебелью и может применяться лишь в исключительных случаях для очищения локальных загрязнений.',
    },
    {
      id: 2,
      text: 'Специальные чистящие средства необходимо подбирать для каждого типа ткани, чтобы не повредить структуру материала.',
    },
    {
      id: 3,
      text: 'Категорически не рекомендуется стирать обивочную ткань во избежание её деформации, потери качества и первоначальных характеристик изделия.',
    },
    {
      id: 4,
      text: 'При первичном использовании чистящего средства его следует проверить на малозаметном участке ткани, и потом нанести небольшое количество средства на губку. Нельзя наливать чистящие средства непосредственно на поверхность ткани!',
    },
    {
      id: 5,
      text: 'Перед любой влажной чисткой необходимо тщательно очистить поверхность от пыли сухой щеткой или пылесосом.',
    },
    {
      id: 6,
      text: 'Никогда нельзя применять одновременно несколько чистящих средств или средства друг за другом.',
    },
    {
      id: 7,
      text: 'Влажным способом можно удалять только свежие пятна. Загрязнение следует удалить губкой, тряпкой или бумажным полотенцем промакивающими движениями как можно быстрее и ни в коем случае НЕ ТЕРЕТЬ, чтобы не позволить грязи глубже проникнуть в структуру ткани.',
    },
    {
      id: 8,
      text: 'Чтобы от моющего средства не образовался ореол, желательно обрабатывать поверхность мебели от шва до шва. Но не мочить обивку!',
    },
    {
      id: 9,
      text: 'Любые пятна следует удалять легкими круговыми движениями, которые идут от края загрязнения к центру.',
    },
    {
      id: 10,
      text: 'Очень важно хорошо просушить мебель после влажной чистки – открыть окна, включить отопление, использовать вентилятор или холодный фен. Если влага останется внутри обивки дольше 12 часов или проникнет в наполнитель, изделие может начать издавать прелый запах.',
    },
    {
      id: 11,
      text: 'Не стоит отскабливать засохшие пятна с обивочной ткани во избежание повреждений структуры материала.',
    },
    {
      id: 12,
      text: 'Универсальным и безопасным способом быстрого удаления пятен с ткани является использование влажных салфеток. Они умеренно влажные, не смачивают излишне обивку и пропитаны щадящими составами, которые деликатно справляются с загрязнениями.',
    },
    {
      id: 13,
      text: 'Нельзя использовать для очистки мебельной ткани агрессивные жидкости и вещества – например, хлор, кислоту, уксус, соду. Также нужно отказаться от любых чистящих средств, в составе которых имеются абразивы.',
    },
  ],
}

export const mockDataRecommendationCard2 = {
  image: RecommendationImg2,
  title: 'СУХАЯ ЧИСТКА',
  content: [
    {
      id: 1,
      text: 'Влажная чистка не показана для проведения для повседневного ухода за мебелью и может применяться лишь в исключительных случаях для очищения локальных загрязнений.',
    },
    {
      id: 2,
      text: 'Специальные чистящие средства необходимо подбирать для каждого типа ткани, чтобы не повредить структуру материала.',
    },
    {
      id: 3,
      text: 'Категорически не рекомендуется стирать обивочную ткань во избежание её деформации, потери качества и первоначальных характеристик изделия.',
    },
    {
      id: 4,
      text: 'При первичном использовании чистящего средства его следует проверить на малозаметном участке ткани, и потом нанести небольшое количество средства на губку. Нельзя наливать чистящие средства непосредственно на поверхность ткани!',
    },
    {
      id: 5,
      text: 'Перед любой влажной чисткой необходимо тщательно очистить поверхность от пыли сухой щеткой или пылесосом.',
    },
    {
      id: 6,
      text: 'Никогда нельзя применять одновременно несколько чистящих средств или средства друг за другом.',
    },
    {
      id: 7,
      text: 'Влажным способом можно удалять только свежие пятна. Загрязнение следует удалить губкой, тряпкой или бумажным полотенцем промакивающими движениями как можно быстрее и ни в коем случае НЕ ТЕРЕТЬ, чтобы не позволить грязи глубже проникнуть в структуру ткани.',
    },
    {
      id: 8,
      text: 'Чтобы от моющего средства не образовался ореол, желательно обрабатывать поверхность мебели от шва до шва. Но не мочить обивку!',
    },
    {
      id: 9,
      text: 'Любые пятна следует удалять легкими круговыми движениями, которые идут от края загрязнения к центру.',
    },
    {
      id: 10,
      text: 'Очень важно хорошо просушить мебель после влажной чистки – открыть окна, включить отопление, использовать вентилятор или холодный фен. Если влага останется внутри обивки дольше 12 часов или проникнет в наполнитель, изделие может начать издавать прелый запах.',
    },
    {
      id: 11,
      text: 'Не стоит отскабливать засохшие пятна с обивочной ткани во избежание повреждений структуры материала.',
    },
    {
      id: 12,
      text: 'Универсальным и безопасным способом быстрого удаления пятен с ткани является использование влажных салфеток. Они умеренно влажные, не смачивают излишне обивку и пропитаны щадящими составами, которые деликатно справляются с загрязнениями.',
    },
    {
      id: 13,
      text: 'Нельзя использовать для очистки мебельной ткани агрессивные жидкости и вещества – например, хлор, кислоту, уксус, соду. Также нужно отказаться от любых чистящих средств, в составе которых имеются абразивы.',
    },
  ],
}
