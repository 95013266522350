import React from 'react';
import "./style.scss"

const Mouse = () => {
    return (
        <div className="ui-help__mouse">
            <i className="ui-help__mouse__wheel"></i>
        </div>
    );
};

export default Mouse;
