import React from 'react';
import './style.scss';

const PullDownHand = () => (
    <div className="pull-down-hand">
        <img src={require('../../assets/images/slider/hand-mobile.png')} alt="Pull Down Hand" className="hand-img" />
    </div>
);

export default PullDownHand;
