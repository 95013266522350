import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'

import Slide from '../slide'

import './style.scss'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const CustomCarousel = ({
  slides,
  infinite = true,
  className,
  hideTextUntilActive = false,
  main = false,
}) => {
  const [slidesToShow, setSlidesToShow] = useState(1.01)
  const [showDots, setShowDots] = useState(false)
  const [activeSlide, setActiveSlide] = useState(0)
  const [hoveredSlide, setHoveredSlide] = useState(null)

  const sliderRef = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlidesToShow(1)
        setShowDots(true)
      } else {
        setSlidesToShow(1.01)
        setShowDots(false)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const settings = {
    infinite: infinite,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    speed: 500,
    dots: showDots,
    arrows: false,
    beforeChange: (current, next) => {
      setActiveSlide(next)
      if (hoveredSlide === next) {
        setHoveredSlide(null)
      }
    },
  }

  const handleNextSlideClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  const isMainClass = main ? 'custom-slider main-slider' : 'custom-slider'

  return (
    <Slider
      {...settings}
      className={isMainClass}
      ref={sliderRef}
    >
      {slides.map((slide, index) => {
        const isNextSlide = index === (activeSlide + 1) % slides.length
        const isCurrentSlide = index === activeSlide
        const isHovered =
          index === hoveredSlide ||
          (hoveredSlide === activeSlide + 1 && isCurrentSlide)

        return (
          <div
            key={index}
            className={`slide-container ${
              isNextSlide ? 'next-slide' : isCurrentSlide ? 'current-slide' : ''
            } ${isHovered ? 'hovered' : ''}`}
            onClick={isNextSlide ? handleNextSlideClick : undefined}
            onMouseEnter={() => {
              if (isNextSlide) {
                setHoveredSlide(index)
              }
            }}
            onMouseLeave={() => {
              setHoveredSlide(null)
            }}
          >
            <Slide
              main={main}
              className={`${className}`}
              image={slide.img}
              header={slide.header}
              number={slide.number}
              title={slide.title}
              description={slide.text}
              hideTextUntilActive={hideTextUntilActive}
              isActive={isCurrentSlide}
            />
          </div>
        )
      })}
    </Slider>
  )
}

export default CustomCarousel
