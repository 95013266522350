import { Image } from 'antd'

import React, { useEffect, useState } from 'react'

import PullDownHand from '../hand-mobile'
import Icon from '../icon'
import Mouse from '../mouse'

import './style.scss'

const Slide = ({
  image,
  header,
  number,
  title,
  description,
  className,
  isActive,
  hideTextUntilActive,
  onMouseEnter,
  white = false,
  main = false,
}) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768) // Условие для мобильных устройств
    }

    handleResize() // Проверка при первой загрузке
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const shouldShowContent = !hideTextUntilActive || isActive
  const height = title === 'MEDINA KAS' ? '130%' : undefined

  return (
    <div
      className={`slide-container ${className}`}
      onMouseEnter={onMouseEnter}
    >
      {main && (isMobile ? <PullDownHand /> : <Mouse />)}
      <Image
        src={image}
        alt={title || header}
        width="100%"
        height={height}
        className="slide-image"
        preview={false}
      />
      {shouldShowContent && (
        <div className={`slide-content ${className}`}>
          {number && <p className="title slide-header">{number}</p>}
          {header && <p className="title slide-header">{header}</p>}
          {title && <p className="title slide-title">{title}</p>}
          {(description || main) && (
            <div className="text-arrow">
              {main && (
                <Icon
                  className={`${className}`}
                  name={white ? 'arrow_small_active' : 'arrow_small'}
                />
              )}
              {description && <p className="text">{description}</p>}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Slide
